import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../slices/auth";
import notification from "../slices/notification";
import getclinicInfo from "../slices/ClinicInfo"
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import FiltersSlice from '../slices/FiltersSlice';
const encryptor = encryptTransform({
    secretKey: 'Anurag_Chauhan_7248',
});
const reducers = combineReducers({
    auth: authReducer,
    ClinicInfo: getclinicInfo,
    notification: notification,
    filters: FiltersSlice
});
const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
    // blacklist: ["filters"],
    // blacklist: ['auth', "message", "clinicDetails"],
    // whitelist: ["ClinicInfo"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export default store;