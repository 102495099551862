import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    Clinic: {
      page: "clinic",
      filters: {},
      pagination: 1,
    },
    ClinicAppointment: {
      page: "clinic-appointment",
      filters: {},
      pagination: 1,
    },
    ClinicCheckout: {
      page: "clinic-checkout",
      filters: {},
      pagination: 1,
    },
    BillingTeam: {
      page: "billing-team",
      filters: {},
      pagination: 1,
    },
    Credentialing: {
      page: "credentialing",
      filters: {},
      pagination: 1,
    },
    SuperBill: {
      page: "super-bill",
      filters: {},
      pagination: 1,
    },
    Claims: {
      page: "claims",
      filters: {},
      pagination: 1,
    },
    FollowUp: {
      page: "follow-up",
      filters: {},
      pagination: 1,
    },
    PaymentReport: {
      page: "payment-report",
      filters: {},
      pagination: 1,
    },
    PatientBalanceReport: {
      page: "patient-balance-report",
      filters: {},
      pagination: 1,
    },
    PaymentsList: {
      page: "payments-list",
      filters: {},
      pagination: 1,
    },
    PaymentsReconcile: {
      page: "payments-reconcile",
      filters: {},
      pagination: 1,
    },
  },
  reducers: {
    addFilter(state, action) {
      const { page, filter } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = { ...state[page].filters, ...filter };
      }
    },
    update(state, action) {
      const { page, updates } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = { ...state[page].filters, ...updates };
      }
    },
    resetFilter(state, action) {
      const { page } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = {};
      }
    },
  },
});

export const { addFilter, update, resetFilter } = filterSlice.actions;
export default filterSlice.reducer;
