// import { Route, Routes } from "react-router-dom"
import './App.css';
import './common/Style.css'
import 'react-toastify/dist/ReactToastify.css';
import "rc-time-picker/assets/index.css";
import AllRoutes from "./Routes/AllRoutes";

function App() {
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
