
import { createSlice } from "@reduxjs/toolkit";


const clinicInfoSlice = createSlice({
    name: "ClinicInfo",
    initialState:{},
    reducers: {
        AddClinicInfo: (state, action) => {
            // console.log(action.payload, "action.payload")
            return action.payload;
        },

    },
});

const { reducer, actions } = clinicInfoSlice;

export const { AddClinicInfo } = actions
export default reducer;