import React from 'react'
import { Skeleton, Stack } from '@mui/material';

const SkeletonAllRoutes = () => {
    return (
        <Stack spacing={1}>
            <div className='row'>
                <div className='col-4 col-lg-5'>
                    <Skeleton variant="text" width={80} height={60} sx={{ marginLeft: "1rem" }} />
                </div>
                <div className='col-2 col-lg-1'>
                    <Skeleton variant="text" height={60} sx={{ marginLeft: "1rem" }} />
                </div>
                <div className='col-2 col-lg-1'>
                    <div className='d-flex'>
                        <Skeleton variant="text" width={"80%"} height={60} />
                        <Skeleton className='ms-2 mt-2' variant="circular" width={40} height={40} />
                    </div>
                </div>
                <div className='col-1 '>
                    <Skeleton className='ps-0' variant="text" height={60} />
                </div>
                <div className='col-3 col-lg-2'>
                    <Skeleton variant="text" height={60} />
                </div>
                <div className='col-1 '>
                    <div className='d-flex justify-content-end'>
                        <Skeleton className='me-2' variant="circular" width={40} height={40} sx={{ marginTop: "10px" }} />
                    </div>
                </div>
            </div>

            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Skeleton variant="rectangular" width={"100%"} height={"70vh"} />
        </Stack>
    )
}

export default SkeletonAllRoutes