import { createSlice } from "@reduxjs/toolkit";


const clinicInfoSlice = createSlice({
    name: "notification",
    initialState:"",
    reducers: {
        addNotification: (state, action) => {
            return action.payload;
        },

    },
});

const { reducer, actions } = clinicInfoSlice;

export const { addNotification } = actions
export default reducer;