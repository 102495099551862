import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
    initialState: {
        user: {}
    },
    name: "auth",
    reducers: {
        add(state, action) {
            state.user = action.payload;
        },
        update(state, action) {
            state.user = { ...state.user, ...action.payload }
        },
        clearStore(state, action) {
            Object.keys(state).forEach(key => {
                delete state[key];
            });
        }
    }
});

export const { add, update, clearStore } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;